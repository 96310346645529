import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Progress } from "reactstrap";

function ProgressTrackerModal({ isOpen, toggle,achievementList,name }) {
  
  const getProgress = (total, unlock) => {
    return Math.round(Number((unlock / total) * 100));
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="custom-modal tracker-modal"
    >
      <ModalHeader className="justify-content-center w-100">
        <p className="font-21 fw-semibold mb-1 pb-3 border-bottom-blue">
          Progress Tracker
        </p>
      </ModalHeader>
      <ModalBody>
        {Boolean(achievementList?.result?.length !== 0) ? (
          <div className="text-wrap">
            <p className="font-18 fw-semibold color-blue">
              {name}
            </p>
            <div className="text-center task-completion d-flex align-items-center mb-3 pb-3 black-bottom-border">
              <Progress
                value={getProgress(
                  achievementList?.totalAchievement,
                  achievementList?.totalUnlockAchievement
                )}
                className="progress custom-progress h-7"
              />
              <div className="font-14 fw-normal ms-2">
                {getProgress(
                  achievementList?.totalAchievement,
                  achievementList?.totalUnlockAchievement
                )}
                %
              </div>
            </div>
            <p className="font-18 fw-semibold text-center">
              Unfinished({achievementList?.totalRemainingAchievement})
            </p>
            <ul className="mb-3 pb-3 black-bottom-border">
              {achievementList?.remain?.length === 0 ? (
                <p className="font-14 text-center">-</p>
              ) : (
                achievementList?.remain?.map(({_id,achievementName,achievementPoints}) => (
                  <li
                    key={_id}
                    className="font-14 fw-normal d-flex justify-content-between mb-10"
                  >
                    {achievementName}
                    <span>{achievementPoints}</span>
                  </li>
                ))
              )}
            </ul>
            <p className="font-18 fw-semibold text-center">
              Finished({achievementList?.totalUnlockAchievement})
            </p>
            <ul>
              {achievementList?.notRemain?.length === 0 ? (
                <p className="font-14 text-center">-</p>
              ) : (
                achievementList?.notRemain?.map(({_id,achievementName,achievementPoints}) => (
                  <li
                    key={_id}
                    className="font-14 fw-semibold d-flex justify-content-between mb-10"
                  >
                    {achievementName}
                    <span>{achievementPoints}</span>
                  </li>
                ))
              )}
            </ul>
          </div>
        ) : (
          <div className="no-data-content-block">
            <p className="font-14 fw-normal text-center">No Tracker Found</p>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

export default ProgressTrackerModal;
