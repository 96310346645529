import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
} from "reactstrap";

function ModifyRoomModal({
  handleUpdateRoom,
  roomName,
  description,
  isOpen,
  toggle,
}) {
  const [roomDetail, setRoomDetail] = useState({
    roomName: roomName,
    description: description,
  });

  const [errors, setErrors] = useState({ roomName: "", description: "" });

  const isFormValid = () => {
    const { roomName, description } = roomDetail;
    const { roomName: nameError, description: descriptionError } = errors;
    if (roomName && description) {
      if (!nameError && !descriptionError) {
        return true;
      } else {
        return false;
      }
    } else {
      const requiredMessage = "This Field is Required";
      const nameErrormsg = roomName ? nameError : requiredMessage;
      const descriptionErrormsg = description
        ? descriptionError
        : requiredMessage;
      setErrors({
        roomName: nameErrormsg,
        description: descriptionErrormsg,
      });
      return false;
    }
  };
  const CheckValidate = (roomName, value) => {
    const requiredMessage = "This Field is Required";
    switch (roomName) {
      case "roomName": {
        const err = value
          ? value.length > 1 && value.length < 51
            ? ""
            : "Name should be between 2 to 50 characters."
          : requiredMessage;
        return err;
      }
      case "description": {
        const err = value
          ? value.length < 151
            ? ""
            : "Description should be less 150 characters"
          : requiredMessage;
        return err;
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const err = CheckValidate(name, value);
    setRoomDetail({ ...roomDetail, [name]: value });
    setErrors({ ...errors, [name]: err });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFormValid()) {
      const afterTrim = {
        roomName: roomDetail.roomName.trim(),
        description: roomDetail.description.trim(),
      };
      handleUpdateRoom(afterTrim);
    } else {
      console.log("error", errors);
    }
  };
  const handleClose = () => {
    setRoomDetail({
      roomName: roomName,
      description: description,
    });
    setErrors({ roomName: "", description: "" });
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal">
      <ModalHeader className="justify-content-center">Modify room</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group-wrap">
            <div className="form-group">
              <label className="font-16 fw-normal">Name</label>
              <Input
                type="text"
                className="form-control secondary-input font-18 fw-medium"
                placeholder="Room name"
                name="roomName"
                value={roomDetail.roomName}
                onChange={handleChange}
                maxLength="51"
                {...(errors.roomName && { invalid: true })}
              />
              <FormFeedback>{errors.roomName}</FormFeedback>
            </div>
            <div className="form-group">
              <label className="font-16 fw-normal">Description</label>
              <Input
                type="textarea"
                className="form-control secondary-input font-18 fw-medium"
                placeholder="Room description"
                name="description"
                value={roomDetail.description}
                onChange={handleChange}
                maxLength="151"
                {...(errors.description && { invalid: true })}
              />
              <FormFeedback>{errors.description}</FormFeedback>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="modal-btn font-16 fw-semibold gray-background-button"
            title="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="modal-btn font-16 fw-normal green-background-button"
            title="Create"
          >
            Update
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default ModifyRoomModal;
