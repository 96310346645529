import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import notificationWhite from "assets/images/notification.png";
import deleteWhite from "assets/images/icons/remove-white.svg";
import updateWhite from "assets/images/icons/update-white.svg";
import publishedWhite from "assets/images/icons/published-white.svg";
import { http, getDateInFormat } from "utils";

function NotificationBell() {
  const notificationIcons = [publishedWhite, updateWhite, deleteWhite];
  const [notificationList, setNotificationList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    http("get", "user/settings/notification/list", {}, true).then(
      (response) => {
        setNotificationList(response.data);
      }
    );
  }, []);

  const clearNotifications = () => {
    http("get", "user/settings/notification/clear", {}, true).then(() => {
      setNotificationList([]);
    });
  };

  return (
    <div className="notification-wrapper">
      <i className="notification-icon notification-menu d-none">
        <img src={notificationWhite} alt="notification-white" />
      </i>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret className="notification-btn">
          <i className="notification-icon notification-menu">
            <img src={notificationWhite} alt="notification-white" />
          </i>
        </DropdownToggle>
        <DropdownMenu right className="notification-dropdown">
          {notificationList.length === 0 ? (
            <DropdownItem>
              <p className="font-14 fw-light">
                Either you don't have <br />
                any notifications or you have
                <br /> turned it off!Explore Real Immerse to get <br />
                the notification.
              </p>
            </DropdownItem>
          ) : (
            <>
              {notificationList.map(({ _id, message, imageTag, createdAt }) => (
                <DropdownItem key={_id}>
                  <div className="icon-wrap blue">
                    <i className="icon">
                      <img
                        src={notificationIcons[imageTag]}
                        alt="notification"
                      />
                    </i>
                  </div>
                  <div className="text-wrap">
                    <p className="font-16 notification-text">{message}</p>
                    <span className="d-block font-13 text-right">
                      {getDateInFormat(createdAt)}
                    </span>
                  </div>
                </DropdownItem>
              ))}
              <button className="clear-all-btn" onClick={clearNotifications}>
                Clear
              </button>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NotificationBell;
