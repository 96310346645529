import { ADDROOM_SUCCESS, SOCIAL_FLAG } from "./social.constant";

const initialState = {
  addRoomResponse: {},
  socialFlag: false,
};
const socialReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDROOM_SUCCESS:
      return{
        ...state,
        addRoomResponse: action.payload,
      }
    case SOCIAL_FLAG:
      return{
        ...state,
        socialFlag: action.payload
      }
    default:
      return state;
  }
};

export default socialReducer;