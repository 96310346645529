import {
  ADDROOM_BEGIN,
  ADDROOM_SUCCESS,
  ADDROOM_FAILURE,
  SOCIAL_FLAG,
} from "./social.constant";
import { http } from "./../../../utils/index";
import { showMessage } from "store/common.action";
import { changeCurrentCard } from "layout/Midbar/store/midbar.action";

export const addRoomBegin = () => {
  return {
    type: ADDROOM_BEGIN,
  };
};

export const addRoomSuccess = (payload) => {
  return {
    type: ADDROOM_SUCCESS,
    payload,
  };
};

export const addRoomFailure = (error) => {
  return {
    type: ADDROOM_FAILURE,
    payload: error,
  };
};

export const changeSocialFlag = (payload) => {
  return {
    type: SOCIAL_FLAG,
    payload,
  };
};

export const addRoom = (payload, successCB) => {
  const request = http("POST", "user/rooms/addToMyRoom", payload, true);
  return (dispatch) => {
    dispatch(addRoomBegin());
    request
      .then((response) => {
        dispatch(changeSocialFlag(true));
        // dispatch(updateMidbarData([]));
        dispatch(changeCurrentCard(null));
        dispatch(showMessage(response?.message, "info"));
        dispatch(addRoomSuccess(response.result));
        successCB();
      })
      .catch((error) => {
        console.log(error);
        dispatch(addRoomFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};
