import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header.js";
import DashboardRecent from "./DashboardRecent.js";
// import DashboardNew from "./DashboardNew.js";
import ProgressTracker from "./ProgressTracker.js";
import {
  dashboardFetch,
  dashboardTrackerFetch,
} from "./store/dashboard.action.js";
import DashboardSkeleton from "components/Skeletons/DashboardSkeleton.js";
import ProgressTrackerSkeleton from "components/Skeletons/ProgressTrackerSkeleton.js";

function Dashboard() {
  const dispatch = useDispatch();
  const { dashboardList, progressList } = useSelector(
    ({ dashboard }) => dashboard
  );
  const { dashboardLoading, progressLoading } = useSelector(
    ({ dashboard }) => dashboard
  );

  useEffect(() => {
    dispatch(dashboardFetch());
    dispatch(dashboardTrackerFetch());
  }, [dispatch]);

  return (
    <div
      className="content-wrapper-block d-block dashboard"
      style={{ minWidth: "0" }}
    >
      <Header />
      {dashboardLoading ? (
        <DashboardSkeleton />
      ) : (
        <>
          {/* {dashboardList?.recent_rooms_data && (
            <DashboardRecent
              recentList={dashboardList.recent_rooms_data}
              title="Recently Accessed"
            />
          )} */}

          {Boolean(dashboardList.length) && (
            <DashboardRecent
              recentList={dashboardList}
              title="Recently Added"
            />
          )}
        </>
      )}
      {/* {dashboardList?.whats_new_data && (
            <DashboardNew whatsNewList={dashboardList.whats_new_data} />
          )} */}
      {progressLoading ? (
        <ProgressTrackerSkeleton />
      ) : (
        Boolean(progressList.length) &&  <ProgressTracker achievementList={progressList} />
      )}
    </div>
  );
}

export default Dashboard;
