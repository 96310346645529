import WhatsNew from "./WhatsNew";

const whatsNewPageConfig = {
  component: WhatsNew,
  path: "/whats-new",
  exact: true,
  layout: {
    config: {
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      prelogin: false,
    },
  },
  auth: true,
};

export default whatsNewPageConfig;
