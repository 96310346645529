import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Input, FormFeedback } from "reactstrap";
import logoWhiteLarge from "assets/images/logo-white-large.png";
import mailWhite from "assets/images/icons/mail-white.svg";
import questionWhite from "assets/images/icons/question-mark-red.svg";
import ShowIcon from "assets/images/icons/show-password.svg";
import HideIcon from "assets/images/icons/hidden-password.svg";
import "assets/css/pre-login-screens.css";
import { fetchLogin } from "./store/login.action";
import PreLoginFooter from "components/PreLoginFooter/PreLoginFooter";

const validEmailRegex = /\S+@\S+\.\S+/;
function Login() {
  const [user, setUser] = useState({
    emailId: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const initialErrorState = { emailId: "", password: "" };
  const [errors, setErrors] = useState(initialErrorState);
  let history = useHistory();
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      history.push("/");
    }
  }, [history]);
  
  const isFormValid = () => {
    const { emailId, password } = user;
    const { emailId: emailError, password: passwordError } = errors;
    if (emailId && password) {
      if (!emailError && !passwordError) {
        return true;
      } else {
        return false;
      }
    } else {
      const requiredMessage = "This Field is Required";
      const emailErrormsg = emailId ? emailError : requiredMessage;
      const passwordErrormsg = password ? passwordError : requiredMessage;
      setErrors({ emailId: emailErrormsg, password: passwordErrormsg });
      return false;
    }
  };
  
  const CheckValidate = (name, value) => {
    const requiredMessage = "This Field is Required";
    switch (name) {
      case "emailId": {
        const err = value
          ? validEmailRegex.test(value)
            ? ""
            : "Please enter a valid email."
          : requiredMessage;
        return err;
      }
      case "password": {
        const err = value ? "" : requiredMessage;
        return err;
      }
      default: {
        return "";
      }
    }
  };
  
  const handleShowPassowrd = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setRememberMe(event.target.checked);
    } else {
      const { name, value } = event.target;
      const err = CheckValidate(name, value);
      setUser({ ...user, [name]: value });
      setErrors({ ...errors, [name]: err });
      if(loginErrorMessage){
        setLoginErrorMessage("");
      }
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const enc = {
        emailId: window.btoa(user.emailId),
        password: window.btoa(user.password),
      };
      localStorage.setItem(
        "rememberMe",
        JSON.stringify({ flag: rememberMe, ...enc })
      );
      const successCB = (response) => {
        history.push("/");
      };
      const errorCB = (message) => {
        setLoginErrorMessage(message);
      };
      const payload = user;
      dispatch(fetchLogin(payload, successCB, errorCB));
    } else {
      console.log("error", errors);
    }
  };

  // const handleRequestAccess = () => {
  //   window.location.href = "https://realimmerse.app/";
  // };

  return (
    <div className="pre-login-screen">
      <div className="pre-login-inner mw-100">
        <div className="logo-white-large">
          <img alt="" src={logoWhiteLarge} />
        </div>
        <div className="pre-login-page-content-block">
          <h1 className="font-22 fw-medium page-title text-center">Login</h1>
          <form>
            <div className="form-group-wrap">
              <div className="form-group">
                <div className="input-wrap icon-right">
                  <i className="email-icon">
                    <img src={mailWhite} alt="mail-white" />
                  </i>
                  <Input
                    type="email"
                    className="input-primary font-16 fw-medium"
                    placeholder="Email Address"
                    name="emailId"
                    onChange={handleChange}
                    value={user.emailId}
                    {...(errors.emailId && { invalid: true })}
                  />
                  <FormFeedback className="font-16 fw-medium">
                    {errors.emailId}&nbsp;&nbsp;
                    <i className="question-mark d-inline-block">
                      <img src={questionWhite} alt="question-mark-white" />
                    </i>
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrap icon-right">
                  {showPassword ? (
                    <i className="lock-icon" onClick={handleShowPassowrd}>
                      <img src={ShowIcon} alt="show-password" />
                    </i>
                  ) : (
                    <i className="lock-icon" onClick={handleShowPassowrd}>
                      <img src={HideIcon} alt="hide-white" />
                    </i>
                  )}
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="input-primary font-16 fw-medium"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={user.password}
                    {...(errors.password && { invalid: true })}
                  />
                  <FormFeedback className="font-16 fw-medium">
                    {errors.password}&nbsp;&nbsp;
                    <i className="question-mark d-inline-block">
                      <img src={questionWhite} alt="question-mark-white" />
                    </i>
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center flex-wrap">
                <div className="white-checkbox align-items-center mb-2">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleChange}
                    value={rememberMe}
                  />
                  <span className="checkbox-square"></span>
                  <label className="checkbox-label font-14 fw-normal mb-0">
                    Remember me
                  </label>
                </div>
                <Link
                  to="/forgot-password"
                  className="font-14 fw-normal link-text mb-2"
                  title="Forgot Password?"
                >
                  Forgot Password?
                </Link>
              </div>
              {loginErrorMessage && (
                <p className="text-danger font-15 fw-semibold text-center">
                  {loginErrorMessage}
                </p>
              )}
              <div className="form-group d-flex action-btn">
                <button
                  className="green-background-button w-100 font-16 fw-normal"
                  title="Login"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
              {/* <div className="form-group">
                <p className="font-14 fw-normal">Don’t have an access?</p>
                <div className="d-flex">
                  <button
                    type="button"
                    className="blue-background-button w-100 font-16 fw-normal"
                    title="Request Access"
                    onClick={handleRequestAccess}
                  >
                    Request Access
                  </button>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <PreLoginFooter />
    </div>
  );
}

export default Login;
