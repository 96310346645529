import { useCallback, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "./components/Title";
import EachDashRecentCard from "./components/EachDashRecentCard";

function DashboardRecent(props) {
  const { recentList } = props;
  const [dragging, setDragging] = useState(false);
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);
  var settings = {
    slidesToShow: 6.1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-25">
      <Title title={props.title} />
      <div className="mt-25">
        {recentList.length === 0 ? (
          <p className="font-18 fw-medium text-center">No content</p>
        ) : (
          <Slider
            className="image-slider dashboard-slider no-slider dashboard-image-slider"
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
            {...settings}
          >
            {recentList?.map((eachCard) => (
              <EachDashRecentCard
                key={eachCard._id}
                room_id={eachCard._id}
                asset_id={eachCard?.assetId}
                imageURL={eachCard.bannerImage[eachCard.thumbnailNumber]}
                room_name={eachCard.room_name}
                publisher={eachCard.asset_publisher_name}
                asset_category={eachCard.asset_category}
                card={eachCard.userType}
                assetType={eachCard.assetType}
                asset_platforms={eachCard.asset_platforms}
                dragging={dragging}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default DashboardRecent;
