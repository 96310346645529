import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "assets/css/settings-page.css";
import notificationWhite from "assets/images/icons/notification-white.svg";
import newWhite from "assets/images/icons/new-white.svg";
import feedbackWhite from "assets/images/icons/feedback-white.svg";
import aboutCompanyWhite from "assets/images/icons/about-company-white.svg";
import FeedbackModal from "pages/Settings/FeedbackModal";
import { http } from "utils";
import { showMessage } from "store/common.action";
import { getProfileDetails } from "pages/UserProfile/store/userProfile.action";

function Settings() {
  const dispatch = useDispatch();
  const toggleModal = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const { profileDetails } = useSelector(({ profile }) => profile);
  const { emailId } = JSON.parse(localStorage.getItem("userDetails")) || {};
  const [notification, setNotification] = useState(
    profileDetails.notificationUserPanel
  );
  const [feedback, setFeedback] = useState({ review: "", ratings: "3" });

  useEffect(() => {
    setNotification(profileDetails.notificationUserPanel);
  }, [profileDetails.notificationUserPanel]);

  useEffect(() => {
    http("get", `user/settings/getFeedback?email=${emailId}`, {}, true)
      .then((response) => {
        setFeedback({
          review: response.data.review,
          ratings: response.data.ratings.toString(),
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [emailId]);

  const handleNotification = (event) => {
    setNotification(event.target.checked);
    dispatch(
      getProfileDetails(
        "PUT",
        { notificationUserPanel: event.target.checked },
        () => {}
      )
    );
  };

  const handlefeedBack = (feedback) => {
    const payload = {
      email: emailId,
      platform: "WEB",
      review: feedback.review,
      ratings: feedback.ratings,
    };
    http("post", "user/settings/addFeedback", payload, true)
      .then((response) => {
        dispatch(showMessage(response?.message, "info"));
        toggleModal();
      })
      .catch((error) => {
        dispatch(showMessage(error?.message, "error"));
      });
  };

 
  return (
    <div className="content-wrapper-block visible-mobile">
      <div className="d-flex flex-wrap align-items-center">
        <h1 className="font-22 fw-semibold title-text">Setting</h1>
      </div>
      <div className="settings-nav-list">
        <div className="settings-nav-item d-flex justify-content-between align-items-center">
          <i className="notification-icon d-inline-block">
            <img src={notificationWhite} alt="notification-white" />
          </i>
          <h2 className="font-16 fw-normal mb-0">Notification</h2>
          <div className="green-toggle-switch">
            <input
              type="checkbox"
              className="switch-input"
              checked={notification}
              onChange={handleNotification}
            />
            <div className="switch-layout"></div>
          </div>
        </div>
        <div
          className="settings-nav-item d-flex justify-content-between align-items-center"
          onClick={() => {
            window.open("https://stg.realimmerse.app/whats-new", "_blank");
          }}
        >
          <i className="new-icon d-inline-block">
            <img src={newWhite} alt="new-white" />
          </i>
          <h2 className="font-16 fw-normal mb-0">What’s New ?</h2>
        </div>
        <div
          className="settings-nav-item d-flex justify-content-between align-items-center"
          onClick={toggleModal}
        >
          <i className="feedback-icon d-inline-block">
            <img src={feedbackWhite} alt="feedback-white" />
          </i>
          <h2 className="font-16 fw-normal mb-0">Feedback</h2>
        </div>
        <FeedbackModal
          isOpen={modal}
          toggle={toggleModal}
          handlefeedBack={handlefeedBack}
          feedback={feedback}
          setFeedback={setFeedback}
        />
        <div
          className="settings-nav-item d-flex justify-content-between align-items-center"
          onClick={() => {
            window.open("https://stg.realimmerse.app/about", "_blank");
          }}
        >
          <i className="about-company-icon d-inline-block">
            <img src={aboutCompanyWhite} alt="about-company-white" />
          </i>
          <h2 className="font-16 fw-normal mb-0">About Us</h2>
        </div>
        {/* <div
          className="settings-nav-item d-flex justify-content-between align-items-center"
          onClick={handleLogout}
        >
          <i className="logout-icon d-inline-block">
            <img src={logoutWhite} alt="logout-white" />
          </i>
          <h2 className="font-16 fw-normal mb-0">Logout</h2>
        </div> */}
      </div>
    </div>
  );
}

export default Settings;
