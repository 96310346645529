import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Table } from "reactstrap";

function ProgressTrackerSkeleton() {
  return (
    <div className="content-skeleton">
      <div className="achievements">
        <Table responsive className="custom-table">
          <thead>
            <tr>
              <th className="font-14 fw-normal">
                <SkeletonTheme
                  color="rgba(54, 60, 89, 0.6)"
                  highlightColor="#181f3a"
                >
                  <Skeleton count={1} width={400} className="mt-2 mb-4 me-3" />
                </SkeletonTheme>
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3].map((each) => (
              <tr key={each}>
                <td className="font-20 fw-medium task">
                  <SkeletonTheme
                    color="rgba(54, 60, 89, 0.6)"
                    highlightColor="#181f3a"
                  >
                    <Skeleton
                      count={1}
                      width={400}
                      className="mt-2 mb-4 me-3"
                    />
                  </SkeletonTheme>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ProgressTrackerSkeleton;
