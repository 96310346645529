import { useState } from "react";
import { Input, FormFeedback } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addInvitee } from "./store/room.action";

function InvitedViaEmail() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const { roomDetailsData } = useSelector(({ common }) => common);
  const { currentCard } = useSelector(({ midbar }) => midbar);

  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const CheckValidate = (value) => {
    const requiredMessage = "This Field is Required";
    const err = value
      ? validEmailRegex.test(value)
        ? ""
        : "Please enter a valid email."
      : requiredMessage;
    return err;
  };
  const isFormValid = () => {
    if (email) {
      return true;
    } else {
      const requiredMessage = "This Field is Required";
      const emailErrormsg = email ? error : requiredMessage;

      setError(emailErrormsg);
      return false;
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    const err = CheckValidate(event.target.value);
    setError(err);
  };

  const handleInvite = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const successCB = () => {
        setEmail("");
      };
      const payload = {
        roomId: currentCard,
        invitedUser: email,
        roomName: roomDetailsData.roomName,
        host: roomDetailsData.hostName,
        hostId: roomDetailsData.host,
        assetId: roomDetailsData.assetId,
      };
      dispatch(addInvitee(payload, successCB));
    } else {
      console.log("error", error);
    }
  };
  return (
    <>
      <h4 className="font-20 fw-medium invite-via-title">Invite via email</h4>
      <div className="invite-user-input d-flex align-items-start">
        <form onSubmit={handleInvite} className="d-flex">
          <div className="input-wrap me-3">
            <Input
              type="email"
              className="form-control primary-input font-16 fw-medium"
              onChange={handleChange}
              value={email}
              {...(error && { invalid: true })}
            />
            <FormFeedback>{error}</FormFeedback>
          </div>
          <div className="d-inline-flex invite-btn">
            <button className="green-background-button font-16 fw-normal d-flex align-items-center">
              Invite
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default InvitedViaEmail;
