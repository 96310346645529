import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from "./login.constant";
import { http } from "./../../../utils/index";

export const loginBegin = () => {
  return {
    type: LOGIN_BEGIN,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
  };
};

export const fetchLogin = (payload, successCB, errorCB) => {
  const request = http("POST", "login", payload, false);
  return (dispatch) => {
    dispatch(loginBegin);
    request
      .then((response) => {
        localStorage.setItem("userToken", response?.data?.token);
        localStorage.setItem("userDetails", JSON.stringify(response?.data));
        dispatch(loginSuccess(response?.data));
        successCB(response);
      })
      .catch((error) => {
        errorCB(error.message);
      });
  };
};
