import React, { useCallback } from "react";
import SingleUser from "assets/images/icons/single-user-icon.svg";
import MultiUser from "assets/images/icons/multi-user-icon.svg";
import DesktopIcon from "assets/images/icons/desktop-icon.svg";
import PhoneIcon from "assets/images/icons/phone-icon.svg";
import BrowserIcon from "assets/images/icons/browser-icon.svg";
import OculusIcon from "assets/images/icons/oculus-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changeCurrentCard,
  updateMidbarData,
} from "layout/Midbar/store/midbar.action";
// import { changeRedirectFlag } from "pages/Explore/store/explore.action";

function EachDashRecentCard(props) {
  const assetType = [SingleUser, MultiUser];
  
  const history = useHistory();
  const dispatch = useDispatch();

  const cardClicked = useCallback(
    (e) => {
      if (props.dragging) {
        e.stopPropagation();
      } else {
        dispatch(updateMidbarData([]));
        dispatch(changeCurrentCard(null));
        // localStorage.setItem(
        //   "redirectData",
        //   JSON.stringify({ flag: true, redirectObj: props })
        // );
        history.push("/myroom");
      }
    },
    [dispatch, history, props]
  );

  return (
    <div className="img-section" onClickCapture={cardClicked}>
      <img src={props.imageURL} alt="automobile" />
      <div className="description"></div>
      <div className="recent-title bottom-section">
        <div className="d-flex justify-content-between align-items-center">
          <p className="font-18">{props.room_name}</p>
          <label
            className={`${props.card === "Host" ? "host" : "invited"} font-16`}
          >
            {props.card}
          </label>
        </div>
        <div className="d-flex align-items-center mt-10">
          <i className="user-type d-inline-block me-3">
            <img src={assetType[props.assetType]} alt="multi-user" />
          </i>
         
          {props.asset_platforms.length && (
            <div className="device-type-listing d-flex align-items-center">
              {(props.asset_platforms.includes(0) ||
                props.asset_platforms.includes(3)) && (
                <i className="d-inline-block me-1">
                  <img src={DesktopIcon} alt="desktop" />
                </i>
              )}
              {(props.asset_platforms.includes(1) ||
                props.asset_platforms.includes(2)) && (
                <i className="d-inline-block me-1">
                  <img src={PhoneIcon} alt="desktop" />
                </i>
              )}
              {props.asset_platforms.includes(4) && (
                <i className="d-inline-block me-1">
                  <img src={OculusIcon} alt="desktop" />
                </i>
              )}
              {props.asset_platforms.includes(5) && (
                <i className="d-inline-block me-1">
                  <img src={BrowserIcon} alt="desktop" />
                </i>
              )}
            </div>
          )}
        
        </div>
      </div>
    </div>
  );
}

export default EachDashRecentCard;
