import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoomDetailsTab from "./RoomDetailsTab";
import AboutCompany from "components/AboutCompany";
import designService from "../../services/design.service";

/* import reactStrap components */
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

/* import images */
import backArrowWhite from "assets/images/icons/back-arrow-white.svg";
import ContentSkeleton from "components/Skeletons/ContentSkeleton";
import { fetchRoomDetails } from "store/common.action";
import ExperienceInfoTab from "./ExperienceInfo";
import NoContent from "components/NoContent";

function Social(props) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("social-room-details");
  const loading = useSelector((state) => state.common.roomDetailsLoading);
  // const commonData = useSelector((state) => state.midbar.commonData);
  const currentCard = useSelector((state) => state.midbar.currentCard);

  const midbarData = useSelector((state) => state.midbar.midbarData);
  const listLoading = useSelector((state) => state.midbar.listLoading);
  const roomDetailsData = useSelector((state) => state.common.roomDetailsData);

  designService.setTabContentHeight();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  useEffect(() => {
    currentCard && dispatch(fetchRoomDetails(currentCard, () => {}));
  }, [currentCard, dispatch]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    toggle("social-room-details");
    // eslint-disable-next-line
  }, [currentCard]);

  return midbarData.length === 0 && !loading && !listLoading ? (
    <NoContent />
  ) : midbarData.length === 0 || loading ? (
    <ContentSkeleton />
  ) : (
    <div
      className="content-wrapper-block event-details-block flex-grow-1"
      style={{ minWidth: "0" }}
    >
      <div className="event-title-block d-flex justify-content-between">
        <div className="content-title-wrapper d-flex flex-wrap align-items-center">
          <i
            className="back-arrow-icon"
            onClick={() => designService.toggleDetails()}
          >
            <img src={backArrowWhite} alt="back-arrow-white" />
          </i>
          <h3 className="font-22 fw-semibold event-title">
            {roomDetailsData.roomName}
          </h3>
          <h4
            className="font-16 fw-normal  text-blue"
            onClick={() => {
              window.open(
                `https://stg.realimmerse.app/publisher/${roomDetailsData.publisherId}`,
                "_blank"
              );
            }}
          >
            {roomDetailsData.publisherName}
          </h4>
        </div>
      </div>
      <Nav tabs className="primary-tab-nav d-flex">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "social-room-details",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("social-room-details");
            }}
          >
            Room
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "experience-info",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("experience-info");
            }}
          >
            Info.
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "social-about-company",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("social-about-company");
            }}
          >
            Publisher
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="social-room-details">
          <RoomDetailsTab roomData={roomDetailsData} />
        </TabPane>
        <TabPane tabId="experience-info">
          <ExperienceInfoTab roomData={roomDetailsData} />
        </TabPane>
        <TabPane tabId="social-about-company">
          <h4 className="font-20 fw-medium invite-via-title">
            <AboutCompany data={roomDetailsData?.companyDescription} />
          </h4>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Social;
