import loginConfig from "pages/Login/loginConfig";
import forgotPasswordConfig from "pages/ForgotPassword/forgotPasswordConfig";
import emailSentConfig from "pages/EmailSent/emailSentConfig";
import resetPasswordConfig from "pages/ResetPassword/resetPasswordConfig";
import passwordChangedConfig from "pages/PasswordChanged/passwordChangedConfig";
import dashboardConfig from "pages/Dashboard/dashboardConfig";
import exploreConfig from "pages/Explore/exploreConfig";
import myRoomConfig from "pages/MyRoom/myRoomConfig";
import socialConfig from "pages/Social/socialConfig";
import settingsConfig from "pages/Settings/settingsConfig";
import userProfileConfig from "pages/UserProfile/userProfileConfig";
import noMatchConfig from "pages/NoMatch/noMatchConfig";
import whatsNewPageConfig from "pages/WhasNew/whatsNewConfig";

const routes = [
  loginConfig,
  forgotPasswordConfig,
  emailSentConfig,
  resetPasswordConfig,
  passwordChangedConfig,
  dashboardConfig,
  exploreConfig,
  myRoomConfig,
  socialConfig,
  settingsConfig,
  userProfileConfig,
  whatsNewPageConfig,
  noMatchConfig,
];

export default routes;
