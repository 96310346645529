import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
/* import page specific css*/

import "assets/css/settings-page.css";

/* import settings page images */
import backArrowWhite from "assets/images/icons/back-arrow-white.svg";
import { http } from "utils";
import { showMessage } from "store/common.action";
import { useHistory } from "react-router-dom";

function WhatsNew(props) {
  const [whatsNewData, setWhatsNewData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    http("post", "whats_new/list", {}, true).then((response) => {
      setWhatsNewData(response?.result?.data);
    }).catch((error) => {
      dispatch(showMessage(error?.message,"error"));
    });
  }, [dispatch]);
  return (
    <div className="content-wrapper-block visible-mobile">
      <div className="content-title-wrapper d-flex flex-wrap align-items-center">
        <i className="back-arrow-icon"   onClick={ () => history.push("/settings") }  >
          <img src={backArrowWhite} alt="back-arrow-white"
           />
        </i>
        <h1 className="font-22 fw-semibold title-text">What’s New?</h1>
      </div>
      {whatsNewData.map((data) => {
        return <div className="fw-normal" key={data.data_id}>{`-${data.whats_new_data}`}</div>;
      })}
    </div>
  );
}

export default WhatsNew;
