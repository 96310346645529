import {
  PROFILE_BEGIN,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
} from "./userProfile.constant";

import { http } from "./../../../utils/index";
import { showMessage } from "store/common.action";

export const profileBegin = () => {
  return {
    type: PROFILE_BEGIN,
  };
};

export const profileSuccess = (payload) => {
  return {
    type: PROFILE_SUCCESS,
    payload,
  };
};

export const profileFailure = (error) => {
  return {
    type: PROFILE_FAILURE,
    payload: error,
  };
};

export const getProfileDetails = (method, payload, successCB) => {
  const { enterpriseId } = JSON.parse(localStorage.getItem("userDetails"));
  const request = http(method, `profile?enterpriseId=${enterpriseId}`, payload, true);
  return (dispatch) => {
    dispatch(profileBegin());
    request
      .then((response) => {
        dispatch(profileSuccess(response.data));
        successCB();
      })
      .catch((error) => {
        console.log(error);
        dispatch(profileFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};
