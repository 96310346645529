import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router";
import ImageSlider from "../../components/ImageSlider";
import RoomDetailsList from "components/RoomDetailsList/RoomDetailsList";
import TagsList from "components/TagsList/TagsList";
import CreateRoomModal from "./Components/components/CreateRoomModal";
// import downloadWhite from "assets/images/icons/download-white.svg";
import timerWhite from "assets/images/icons/timer-white.svg";
import memberWhite from "assets/images/icons/member-white.svg";
// import dateTimeWhite from "assets/images/icons/date-time-white.svg";
import publishedWhite from "assets/images/icons/published-white.svg";
// import chatWhite from "assets/images/icons/chat-white.svg";
import tickWhite from "assets/images/icons/tick-white.svg";
import AboutRoom from "components/AboutRoom";
import designService from "../../services/design.service";
import { createRoom } from "./store/explore.action";
import ProgressTrackerModal from "./Components/components/ProgressTrackerModal";
import { getDateInFormat, http } from "utils";

function ListingDetailsTab({ infoData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [imageIndex, setImageIndex] = useState(0);
  const [modal, setModal] = useState({
    create: false,
    achievement: false,
  });
  const [achievementList, setAchievementList] = useState({});

  designService.setTabContentHeight();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  const toggleModal = (type) => {
    setModal({ ...modal, [type]: !modal[type] });
  };
  const thumbnailSelection = (index) => {
    setImageIndex(index);
  };
  const handleCreateRoom = (roomDetail) => {
    const successCB = () => {
      if (infoData.assetType === 1) {
        toggleModal("create");
      }
      history.push({ pathname: "/myroom", state: { loading: true } });
      // history.push("/myroom");
    };
    const payload =
      infoData.assetType === 1
        ? {
            ...roomDetail,
            assetId: infoData._id,
            thumbnailNumber: imageIndex,
            roomStatus: 0,
          }
        : {
            roomName: infoData.name,
            description: infoData.description,
            assetId: infoData._id,
            thumbnailNumber: imageIndex,
            roomStatus: 0,
          };
    dispatch(createRoom(payload, successCB));
  };
  const { createRoomLoading } = useSelector(({ explore }) => explore);

  const handleProgress = () => {
    http(
      "GET",
      `user/achievement/getAchievementList?assetId=${infoData._id}`,
      {},
      true
    )
      .then((response) => {
        setAchievementList(response.data);
        toggleModal("achievement");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const roomDetailsList = [
    {
      id: 0,
      iconClass: "timer-icon",
      img: timerWhite,
      value: infoData?.duration + " minutes" || "-",
    },
    {
      id: 1,
      iconClass: "member-icon",
      img: memberWhite,
      value: infoData?.totalMembersCapacity + " members" || "-",
    },
    // {
    //   id: 2,
    //   iconClass: "date-time-icon",
    //   img: dateTimeWhite,
    //   title: "Created",
    //   value:
    //     infoData?.createdAt &&
    //     (getDateInFormat(infoData?.createdAt) || "-"),
    // },
    // {
    //   id: 3,
    //   iconClass: "date-time-icon",
    //   img: dateTimeWhite,
    //   title: "Room Time",
    //   value: infoData?.total_minutes || "-",
    // },
    {
      id: 4,
      iconClass: "published-icon",
      img: publishedWhite,
      title: "Published",
      value:
        infoData?.createdAt && (getDateInFormat(infoData?.createdAt) || "-"),
    },
    // {
    //   id: 5,
    //   iconClass: "chat-icon",
    //   img: chatWhite,
    //   title: "Sessions Created",
    //   value: infoData?.noOfSession || "-",
    // },
  ];

  return (
    <Scrollbars
      className="tab-pane-inner custom-scrollbar"
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="content-outer" />}
    >
      {infoData?.bannerImages && (
        <ImageSlider
          imageIndex={imageIndex}
          thumbnailSelection={thumbnailSelection}
          data={infoData}
          module="explore"
        />
      )}
      {infoData?.tags?.length && <TagsList tagList={infoData.tags} />}
      <RoomDetailsList
        list={
          infoData?.assetType === 0
            ? roomDetailsList.filter((each) => each.id !== 1)
            : roomDetailsList
        }
      />
      <div className="d-flex mt-25 mb-30 align-items-center">
        <button
          disabled={
            (infoData.assetType === 0 && infoData.roomCreated) ||
            createRoomLoading
          }
          className={`${
            infoData.assetType === 1
              ? "green-background-button"
              : "green-border-button"
          } font-16 fw-normal d-flex align-items-center justify-content-center w-180px me-3`}
          onClick={() =>
            infoData.assetType === 1
              ? toggleModal("create")
              : infoData.roomCreated
              ? null
              : handleCreateRoom()
          }
        >
          {infoData.assetType === 1 ? (
            `${createRoomLoading ? "Loading" : "Create Room"}`
          ) : infoData.roomCreated ? (
            <>
              Room Added
              <i className="right-arrow-icon right-icon tick-icon d-inline-block">
                <img src={tickWhite} alt="tick-white-icon" />
              </i>
            </>
          ) : (
            `${createRoomLoading ? "Loading" : "Add Room"}`
          )}
        </button>
        {infoData.assetType === 1 && (
          <CreateRoomModal
            isOpen={modal.create}
            toggle={() => toggleModal("create")}
            handleCreateRoom={handleCreateRoom}
          />
        )}
        <button
          className="blue-background-button font-16 fw-normal d-flex align-items-center me-3 mb-0"
          onClick={handleProgress}
        >
          Progress Tracker
        </button>
        <ProgressTrackerModal
          isOpen={modal.achievement}
          toggle={() => toggleModal("achievement")}
          achievementList={achievementList}
          name={infoData.name}
        />
      </div>
      {(!infoData?.roomCreated || infoData.assetType === 1) && (
        <p className="font-14 fw-thin opacity-90 ">
          Note : Select thumbnail to create room
        </p>
      )}
      <AboutRoom roomDetails={infoData.description} />
    </Scrollbars>
  );
}

export default ListingDetailsTab;
