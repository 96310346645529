import { useDispatch, useSelector } from "react-redux";
import designService from "../../services/design.service";
import DesktopIcon from "assets/images/icons/desktop-icon.svg";
import PhoneIcon from "assets/images/icons/phone-icon.svg";
import BrowserIcon from "assets/images/icons/browser-icon.svg";
import OculusIcon from "assets/images/icons/oculus-icon.svg";
import MultiUser from "assets/images/icons/multi-user-icon.svg";
import SingleUser from "assets/images/icons/single-user-icon.svg";
import { changeCurrentCard, setCommonCard } from "./store/midbar.action";

function EachCard(props) {
  const dispatch = useDispatch();
  const currentCard = useSelector((state) => state.midbar.currentCard);

  const assetType = [SingleUser, MultiUser];

  const selectMethod = (module) => {
    if (module === "explore") {
      dispatch(changeCurrentCard(props.currentId));
    } else if (module === "myroom") {
      dispatch(changeCurrentCard(props.currentId));
      dispatch(setCommonCard(props.badge));
    } else if (module === "social") {
      dispatch(changeCurrentCard(props.currentId));
    }
  };

  const isCurrent = () => {
    return currentCard === props.currentId;
  };

  return (
    <li
      className={`d-flex ${isCurrent() ? "current" : ""}
      `}
      onClick={() => {
        selectMethod(props.module);
        designService.toggleDetails();
      }}
      ref={props.cardRef}
    >
      <div className="img-wrap">
        <img src={props.imageURL} alt={props.name} />
      </div>
      <div className="text-wrap">
        <h2 className="font-14 block-title fw-normal">{props.name}</h2>
        <p className="font-10 fw-light organizer">{props.publisher}</p>
        {props.category.map((category, index) => (
          <div
            key={`${category}-${index}`}
            className="badge category-label font-10 fw-thin green"
          >
            {category}
          </div>
        ))}
        {props.badge && (
          <div
            className={`badge role-badge font-13 fw-thin ${
              props.badge === "Host" ? "red" : "blue"
            }`}
          >
            {props.badge}
          </div>
        )}
        <div className="d-flex align-items-center mt-15">
          <i className="user-type d-inline-block me-3">
            <img src={assetType[props.asset_type]} alt="multi-user" />
          </i>
          {props.asset_platforms.length && (
            <div className="device-type-listing d-flex align-items-center">
              {(props.asset_platforms.includes(0) ||
                props.asset_platforms.includes(3)) && (
                <i className="d-inline-block me-1">
                  <img src={DesktopIcon} alt="desktop" />
                </i>
              )}
              {(props.asset_platforms.includes(1) ||
                props.asset_platforms.includes(2)) && (
                <i className="d-inline-block me-1">
                  <img src={PhoneIcon} alt="desktop" />
                </i>
              )}
              {props.asset_platforms.includes(4) && (
                <i className="d-inline-block me-1">
                  <img src={OculusIcon} alt="desktop" />
                </i>
              )}
              {props.asset_platforms.includes(5) && (
                <i className="d-inline-block me-1">
                  <img src={BrowserIcon} alt="desktop" />
                </i>
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default EachCard;
