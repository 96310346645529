import {
  DASHBOARD_BEGIN,
  DASHBOARD_FAILURE,
  DASHBOARD_SUCCESS,
  DASHBOARD_TRACKER_BEGIN,
  DASHBOARD_TRACKER_SUCCESS,
  DASHBOARD_TRACKER_FAILURE
} from "./dashboard.constant";
import { http } from "./../../../utils/index";
import { showMessage } from "store/common.action";

export const dashboardBegin = () => {
  return {
    type: DASHBOARD_BEGIN,
  };
};

export const dashboardSuccess = (payload) => {
  return {
    type: DASHBOARD_SUCCESS,
    payload,
  };
};

export const dashboardFailure = (error) => {
  return {
    type: DASHBOARD_FAILURE,
    payload: error,
  };
};

export const dashboardTrackerBegin = () => {
  return {
    type: DASHBOARD_TRACKER_BEGIN,
  };
};

export const dashboardTrackerSuccess = (payload) => {
  return {
    type: DASHBOARD_TRACKER_SUCCESS,
    payload,
  };
};

export const dashboardTrackerFailure = (error) => {
  return {
    type: DASHBOARD_TRACKER_FAILURE,
    payload: error,
  };
};

export const dashboardFetch = () => {
  const request = http("GET", "user/dashboard/recentlyAdded", {}, true);
  return (dispatch) => {
    dispatch(dashboardBegin());
    request
      .then((response) => {
        dispatch(dashboardSuccess(response.data));
      })
      .catch((error) => {
        console.log("dashboard list", error);
        dispatch(dashboardFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const dashboardTrackerFetch = () => {
  const request = http("GET", "user/dashboard/getProgress", {}, true);
  return (dispatch) => {
    dispatch(dashboardTrackerBegin());
    request
      .then((response) => {
        dispatch(dashboardTrackerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(dashboardTrackerFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};