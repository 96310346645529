import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  ModalHeader,
} from "reactstrap";
import "react-image-crop/dist/ReactCrop.css";
import { http } from "utils";
import { useDispatch } from "react-redux";
import { showMessage } from "store/common.action";
import NoImg from "assets/images/icons/no-image.svg";
import Upload from "assets/images/icons/upload-icon.svg";
import { getProfileDetails } from "../store/userProfile.action";

function CropModal({ isOpen, toggle, profileImage }) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const dispatch = useDispatch();
  const previewCanvasRef = useRef(null);
  // const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [crop, setCrop] = useState({ unit: "%", width: 40, aspect: 4 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [currentSelect, setcurrentSelect] = useState("default");

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop, previewCanvasRef]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const handleDefaultImg = () => {
    http("delete", "profile/deleteProfileImage", {}, true)
      .then((response) => {
        dispatch(getProfileDetails("GET",{},()=>{}));
       
        dispatch(showMessage(response?.message, "info"));
        toggle();
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(showMessage(error?.message, "error"));
        toggle();
      });
  };

  const handleClose = () => {
    toggle();
    setUpImg();
    setCrop({ unit: "%", width: 40, aspect: 4 / 4 });
    setCompletedCrop(null);
    setcurrentSelect("default");
  };

  function uploadCropedImage(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    const fileName = `croppedImg.jpeg`;
    canvas.toBlob((blob) => {
      if (!blob) {
        console.log("Canvas is empty");
        return;
      }
      blob.name = fileName;
      const fileUrl = new File([blob], fileName, { type: "image/jpeg" });
      fileUrl &&
        getBase64(fileUrl)
          .then((result) => {
            const payload =   { fileType: "image/jpeg", file: result, type: "profile" };
        
            http("post", "upload-file", payload, true)
              .then((response) => {
                

                const successCB = () => {
                  
                  dispatch(showMessage(response?.message, "info"));
                      setUpImg();
                      setCrop({ unit: "%", width: 30, aspect: 4 / 4 });
                      setCompletedCrop(null);
                      toggle();
                };
        
                dispatch(getProfileDetails("PUT", { profileImage: response.data.name }, successCB));
    
                console.log("upload photo succesfully", response);
              })
              .catch((error) =>{
                console.log("error in upload photo", error.response);
                 dispatch(showMessage(error?.message, "error"));
                toggle();
               } );
          })
          .catch((error) => {
            console.log("base64 error", error);
            dispatch(showMessage("base64 error", "error"));
          });
    }, "image/jpeg");
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal ">
      <ModalHeader className="justify-content-center text-center">
        {!upImg ? "Update User Image" : "Crop uploaded image"}
        {upImg && (
          <p
            className="font-16 mt-15"
            style={{ opacity: ".6", marginBottom: "0" }}
          >
            Please select your profile image area in square
          </p>
        )}
      </ModalHeader>
      <ModalBody>
        {!upImg && (
          <ButtonGroup className="device-group" aria-label="Basic example">
            <Button
              className={`modal-device ${
                currentSelect === "default" && "currentDevice"
              }`}
              name="default"
              onClick={() => setcurrentSelect("default")}
            >
              <img src={NoImg} alt="Browser" className="mb-10" />
              Default
            </Button>
            <label
              htmlFor="upload-image"
              className={`modal-device modal-device-label ${
                currentSelect === "upload" && "currentDevice"
              }`}
              onClick={() => setcurrentSelect("upload")}
            >
              <img src={Upload} alt="Browser" className="mb-20" />
              Upload
            </label>
            <input
              type="file"
              id="upload-image"
              accept="image/png, image/jpg, image/jpeg"
              onChange={onSelectFile}
              name="file"
              style={{
                visibility: "hidden",
                width: 0,
                height: 0,
                display: "none",
              }}
            />
          </ButtonGroup>
        )}
        <div className={`${upImg && "crop-image-section"}`}>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            className="mt-2 crop-image"
            // locked
          />
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: "none",
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold gray-background-button"
          title="Close"
          onClick={handleClose}
        >
          Close
        </button>
        {currentSelect === "default" ? (
          <button
            type="button"
            className="modal-btn font-16 fw-normal green-background-button"
            disabled={!profileImage}
            onClick={handleDefaultImg}
          >
            Update
          </button>
        ) : (
          <button
            type="button"
            className="modal-btn font-16 fw-normal green-background-button"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() =>
              uploadCropedImage(previewCanvasRef.current, completedCrop)
            }
          >
            Update
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default CropModal;
