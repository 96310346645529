import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Input, FormFeedback } from "reactstrap";
/* Import images */
import logoWhiteLarge from "assets/images/logo-white-large.png";
import ShowIcon from "assets/images/icons/show-password.svg";
import HideIcon from "assets/images/icons/hidden-password.svg";
/* Import page specific css */
import "assets/css/pre-login-screens.css";
import { useDispatch } from "react-redux";
import { showMessage } from "../../store/common.action";
import { http } from "utils";

function ResetPassword() {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordUpper, setPasswordUpper] = useState(false);
  const [passwordLower, setPasswordLower] = useState(false);
  const [passwordDigit, setPasswordDigit] = useState(false);
  const [passwordSpChar, setPasswordSpChar] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const CheckValidate = (name, value) => {
    const requiredMessage = "This Field is Required";

    switch (name) {
      case "password": {
        value.length >= 8 ? setPasswordLength(true) : setPasswordLength(false);
        /(?=.*?[a-z])/.test(value)
          ? setPasswordLower(true)
          : setPasswordLower(false);
        /(?=.*?[A-Z])/.test(value)
          ? setPasswordUpper(true)
          : setPasswordUpper(false);
        /\d/.test(value) ? setPasswordDigit(true) : setPasswordDigit(false);
        /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value)
          ? setPasswordSpChar(true)
          : setPasswordSpChar(false);
        return;
      }
      case "confirmPassword": {
        const err = value
          ? value === passwords.password
            ? ""
            : "Password should be same as above"
          : requiredMessage;
        return err;
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswords({ ...passwords, [name]: value });

    const err = CheckValidate(name, value);
    if (name === "confirmPassword") {
      setConfirmPasswordErr(err);
    }
  };

  const isFormValid = () => {
    if (
      passwordLength &&
      passwordUpper &&
      passwordLower &&
      passwordDigit &&
      passwordSpChar &&
      confirmPasswordErr === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleReset = (event) => {
    event.preventDefault();

    if (isFormValid()) {
      // let token = id;
      // const payload = {
      //   newPassword: passwords.password,
      //   confirmPassword: passwords.confirmPassword,
      // };
      const payload = { token:id, password: passwords.password};
      http("post", `reset-password`, payload, false)
        .then((response) => {
          setPasswords({
            password: "",
            confirmPassword: "",
          });
          history.push("/password-changed");
        })
        .catch((error) => {
          setShowError(true);
          console.log("error",error);
          dispatch(showMessage(error?.message, "error"));
        });
    } else {
      console.log("form not validated");
    }
  };

  const handleForgotPassword = () => {
    history.push("/forget-password");
  };

  if (showError) {
    return (
      <div className="pre-login-screen reset-password">
        <div className="pre-login-inner mw-100">
          <div className="logo-white-large">
            <img alt="" src={logoWhiteLarge} />
          </div>
          <div className="pre-login-page-content-block">
            <h1 className="font-22 fw-medium page-title text-center">
              Problem in Resetting
            </h1>
            <p className="text-center mb-4 font-18 fw-normal">
              please sent another reset link
            </p>
            <button
              className="green-border-button w-100 mb-3"
              title="Forgot Password"
              onClick={handleForgotPassword}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pre-login-screen reset-password">
        <div className="pre-login-inner mw-100">
          <div className="logo-white-large">
            <img alt="" src={logoWhiteLarge} />
          </div>
          <div className="pre-login-page-content-block reset-password-content d-flex justify-content-between">
            <div className="checklist">
              <h2 className="font-16 fw-normal block-title">
                Password must contain:
              </h2>
              <p
                className={`checklist-item font-14 fw-normal ${
                  passwordLength ? "text-decoration-line-through" : ""
                }`}
              >
                <span className="opacity-70">At least 8 characters</span>
              </p>
              <p
                className={`checklist-item font-14 fw-normal ${
                  passwordUpper ? "text-decoration-line-through" : ""
                }`}
              >
                <span className="opacity-70">
                  At least 1 upper case letter (A-Z)
                </span>
              </p>
              <p
                className={`checklist-item font-14 fw-normal ${
                  passwordLower ? "text-decoration-line-through" : ""
                }`}
              >
                <span className="opacity-70">
                  At least 1 lower case letter (a-z)
                </span>
              </p>
              <p
                className={`checklist-item font-14 fw-normal ${
                  passwordDigit ? "text-decoration-line-through" : ""
                }`}
              >
                <span className="opacity-70">At least 1 number(0-9)</span>
              </p>
              <p
                className={`checklist-item font-14 fw-normal ${
                  passwordSpChar ? "text-decoration-line-through" : ""
                }`}
              >
                <span className="opacity-70">At least 1 special character</span>
              </p>
            </div>
            <div className="form">
              <h1 className="font-22 fw-medium page-title">
                Reset Your Password
              </h1>
              <form>
                <div className="form-group-wrap">
                  <div className="form-group">
                    <div className="input-wrap icon-right">
                      {showPassword ? (
                        <i
                          className="lock-icon"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img src={ShowIcon} alt="show-password" />
                        </i>
                      ) : (
                        <i
                          className="lock-icon"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img src={HideIcon} alt="password-white" />
                        </i>
                      )}
                      <Input
                        type={showPassword ? "text" : "password"}
                        className="input-primary font-16 fw-medium"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={passwords.password}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-wrap icon-right">
                      {showConfirmPassword ? (
                        <i
                          className="lock-icon"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          <img src={ShowIcon} alt="show-password" />
                        </i>
                      ) : (
                        <i
                          className="lock-icon"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          <img src={HideIcon} alt="password-white" />
                        </i>
                      )}
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        className="input-primary font-16 fw-medium"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        value={passwords.confirmPassword}
                        {...(confirmPasswordErr && { invalid: true })}
                      />
                      <FormFeedback>{confirmPasswordErr}</FormFeedback>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex action-btn mb-0">
                  <button
                    className="green-background-button w-100 font-16 fw-normal"
                    title="Reset Password"
                    onClick={handleReset}
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
