import { Scrollbars } from "react-custom-scrollbars";
import { Table, Progress } from "reactstrap";
import "react-rangeslider/lib/index.css";
import Title from "./components/Title";


function ProgressTracker({ achievementList }) {
  const getProgress = (total, unlock) => {
    return Math.round(Number((unlock / total) * 100));
  };

  return (
    <div className="mt-25">
      <Title title="Progress Tracker" />
      <div className="achievements">
        <Scrollbars
          className="tab-pane-inner custom-scrollbar"
          style={{ height: "400px" }}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          renderThumbHorizontal={(props) => (
            <div {...props} className="thumb-horizontal" />
          )}
        >
          <Table responsive className="custom-table">
            <thead>
              <tr>
                <th style={{ width: "35%" }} className="font-14 fw-normal">
                  Task
                </th>
                <th
                  style={{ width: "65%" }}
                  className="font-14 fw-normal text-center"
                >
                  Completion
                </th>
              </tr>
            </thead>
            {achievementList.length === 0 ? (
              <tbody>
                <tr>
                  <td className="font-18 fw-medium text-center">No content</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {achievementList.map(
                  (
                    {
                      assetName,
                      totalAchievementData,
                      userUnlockAchievementData,
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td
                        style={{ width: "35%" }}
                        className="font-20 fw-medium task"
                      >
                        {assetName}
                      </td>
                      <td
                        style={{ width: "65%" }}
                        className="font-14 fw-normal text-center task-completion"
                      >
                        <Progress
                          value={getProgress(
                            totalAchievementData,
                            userUnlockAchievementData
                          )}
                          className="progress custom-progress w-100"
                        />
                        <label>
                          {getProgress(
                            totalAchievementData,
                            userUnlockAchievementData
                          )}
                          %
                        </label>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </Table>
        </Scrollbars>
      </div>
    </div>
  );
}

export default ProgressTracker;
