import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import "assets/css/sidebar-menu-block.css";
import logo from "assets/images/icons/logo-white.svg";
import dashbaordWhite from "assets/images/icons/dashboard-white.svg";
import dashbaordOrange from "assets/images/icons/dashboard-orange.svg";
import exploreWhite from "assets/images/icons/explore-white.svg";
import exploreOrange from "assets/images/icons/explore-orange.svg";
import roomWhite from "assets/images/icons/room-white.svg";
import roomOrange from "assets/images/icons/room-orange.svg";
import socialWhite from "assets/images/icons/social-white.svg";
import socialOrange from "assets/images/icons/social-orange.svg";
import settingsWhite from "assets/images/icons/settings-white.svg";
import settingsOrange from "assets/images/icons/settings-orange.svg";
import designService from "services/design.service";
import {
  changeCurrentCard, setCommonCard, updateMidbarData,
} from "../Midbar/store/midbar.action";
import { changeSocialFlag } from "pages/Social/store/social.action";
import { getProfileDetails } from "pages/UserProfile/store/userProfile.action";

function Sidebar({ display }) {
  const location = useLocation();
  const currentPanel = location.pathname;
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getProfileDetails("GET",{},()=>{}));
 }, [dispatch]);

  useEffect(() => {
    designService.scrollToActive();
  }, []);
  const profileDetails = useSelector((state) => state.profile.profileDetails);

  if (!display) return null;

 
  const sideNavList = [
    {
      id: 0,
      name: "Dashboard",
      normalImage: dashbaordWhite,
      hoverImage: dashbaordOrange,
      url: "/",
    },
    {
      id: 1,
      name: "Explore",
      normalImage: exploreWhite,
      hoverImage: exploreOrange,
      url: "/explore",
    },
    {
      id: 2,
      name: "My Room",
      normalImage: roomWhite,
      hoverImage: roomOrange,
      url: "/myroom",
    },
    {
      id: 3,
      name: "Social",
      normalImage: socialWhite,
      hoverImage: socialOrange,
      url: "/social",
    },
    {
      id: 4,
      name: "Settings",
      normalImage: settingsWhite,
      hoverImage: settingsOrange,
      url: "/settings",
    },
  ];
  const listItems = sideNavList.map((sideNavListItem) => (
    <li className="text-center" key={sideNavListItem.id}>
      <NavLink
        to={sideNavListItem.url}
        exact
        activeClassName="active"
        className="font-13"
        title={sideNavListItem.name}
        onClick={() => {
          if (currentPanel !== sideNavListItem.url) {
            dispatch(updateMidbarData([]));
            dispatch(changeCurrentCard(null));
            dispatch(
              setCommonCard("")
            );
            localStorage.setItem(
              "redirectData",
              JSON.stringify({
                flag: false,
                redirectObj: {}
              })
            );
            dispatch(changeSocialFlag(false));
          }
        }}
      >
        <i
          className={
            sideNavListItem.name.replace(" ", "-").toLowerCase() + "-icon"
          }
        >
          <img
            src={sideNavListItem.normalImage}
            alt={
              sideNavListItem.name.replace(" ", "-").toLowerCase() + "-white"
            }
            className="normal"
          />
          <img
            src={sideNavListItem.hoverImage}
            alt={
              sideNavListItem.name.replace(" ", "-").toLowerCase() + "-orange"
            }
            className="active"
          />
        </i>
        {sideNavListItem.name}
      </NavLink>
    </li>
  ));
  return (
    <aside className="sidebar-menu-block">
      <div className="logo-nav-block">
        <NavLink className="panel-logo" to="/" exact>
          <i className="logo-icon">
            <img src={logo} alt="logo-white" />
          </i>
        </NavLink>
        <ul className="sidebar-menu">{listItems}</ul>
      </div>
      <NavLink
        to="/userProfile"
        exact
        activeClassName="active"
        className="font-13 profile-link text-center"
        title="User Profile"
      >
        {Object.keys(profileDetails).length && (
          <div className="img-wrap">
            {profileDetails.profileImage ? (
              <img src={profileDetails.profileImage} alt="profile" />
            ) : (
              <h3 className="font-16 fw-normal text-uppercase mb-0">
                {profileDetails?.name?.[0]}
              </h3>
            )}
          </div>
        )}
        Profile
      </NavLink>
    </aside>
  );
}

export default Sidebar;
