import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import loginReducer from "../pages/Login/store/login.reducer";

import exploreReducer from "../pages/Explore/store/explore.reducer";
import roomReducer from "../pages/MyRoom/store/room.reducer";
import socialReducer from "../pages/Social/store/social.reducer";
import profileReducer from "pages/UserProfile/store/userProfile.reducer";
import commonReducer from "./common.reducer";
import midbarReducer from "layout/Midbar/store/midbar.reducer";
import dashboardReducer from "pages/Dashboard/store/dashboard.reducer";


const rootReducer = combineReducers({
  login: loginReducer,
  dashboard: dashboardReducer,
  midbar: midbarReducer,
  explore: exploreReducer,
  room: roomReducer,
  social: socialReducer,
  profile: profileReducer,
  common: commonReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
