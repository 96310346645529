import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Input, FormFeedback } from "reactstrap";

function CreateRoomModal(props) {
  const { handleCreateRoom } = props;
  const [roomDetail, setRoomDetail] = useState({
    roomName: "",
    description: "",
  });
  const [errors, setErrors] = useState({ roomName: "", description: "" });

  const isFormValid = () => {
    const { roomName, description } = roomDetail;
    const { roomName: roomNameError, description: descriptionError } = errors;
    if (roomName && description) {
      if (!roomNameError && !descriptionError) {
        return true;
      } else {
        return false;
      }
    } else {
      const requiredMessage = "This Field is Required";
      const roomNameErrormsg = roomName ? roomNameError : requiredMessage;
      const descriptionErrormsg = description
        ? descriptionError
        : requiredMessage;
      setErrors({
        roomName: roomNameErrormsg,
        description: descriptionErrormsg,
      });
      return false;
    }
  };
  const CheckValidate = (name, value) => {
    const requiredMessage = "This Field is Required";
    switch (name) {
      case "roomName": {
        const err = value
          ? value.length > 1 && value.length < 51
            ? ""
            : "Name should be between 2 to 50 characters"
          : requiredMessage;
        return err;
      }
      case "description": {
        const err = value ? value.length < 151 ? "" : "Description should be less 150 characters" : requiredMessage;
        return err;
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const err = CheckValidate(name, value);
    setRoomDetail({ ...roomDetail, [name]: value });
    setErrors({ ...errors, [name]: err });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const afterTrim = {
        roomName: roomDetail.roomName.trim(),
        description: roomDetail.description.trim(),
      }
      // handleCreateRoom(roomDetail);
      handleCreateRoom(afterTrim);
    } else {
      console.log("error", errors);
    }
  };
  const handleClose = () => {
    props.toggle();
    setRoomDetail({
      roomName: "",
      description: "",
    });
    setErrors({ roomName: "", description: "" });
  }
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
      className="custom-modal"
    >
      <ModalHeader className="justify-content-center">Create Room</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group-wrap">
            <div className="form-group">
              <label className="font-16 fw-normal">Name</label>
              <Input
                type="text"
                className="form-control secondary-input font-18 fw-medium"
                placeholder="Room name"
                name="roomName"
                value={roomDetail.roomName.trimStart()}
                onChange={handleChange}
                maxLength="51"
                {...(errors.roomName && { invalid: true })}
              />
              <FormFeedback>{errors.roomName}</FormFeedback>
            </div>
            <div className="form-group">
              <label className="font-16 fw-normal">
                Description
              </label>
              <Input
                type="textarea"
                className="form-control secondary-input font-18 fw-medium"
                placeholder="Room description"
                name="description"
                value={roomDetail.description.trimStart()}
                onChange={handleChange}
                maxLength="151"
                {...(errors.description && { invalid: true })}
              />
              <FormFeedback>{errors.description}</FormFeedback>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="modal-btn font-16 fw-medium gray-background-button"
            title="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="modal-btn font-16 fw-medium green-background-button"
            title="Create"
          >
            Create
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default CreateRoomModal;
