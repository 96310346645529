const designService = {
  setTabContentHeight: function () {
    setTimeout(() => {
      var targetElem = document.querySelector(
        ".event-details-block .tab-pane-inner"
      );
      if (targetElem) {
        var windowH = window.innerHeight;
        var elementTop = targetElem.getBoundingClientRect().top;
        var wrapperPaddingB = parseInt(
          getComputedStyle(document.querySelector(".main-layout-wrapper"))
            .paddingBottom
        );
        var parentBlockPaddingB = parseInt(
          getComputedStyle(document.querySelector(".event-details-block"))
            .paddingBottom
        );
        var getH =
          windowH -
          elementTop -
          wrapperPaddingB -
          parentBlockPaddingB -
          2 +
          "px";
        targetElem.style.height = getH;
      }
    });
  },

  setCardsListHeight: function () {
    setTimeout(() => {
      var targetElem = document.querySelector(
        ".search-filter-block .events-list"
      );
      if (targetElem) {
        var windowH = window.innerHeight;

        var elementTop = targetElem.getBoundingClientRect().top;
        var wrapperPaddingB = parseInt(
          getComputedStyle(document.querySelector(".main-layout-wrapper"))
            .paddingBottom
        );
        var getH = windowH - elementTop - wrapperPaddingB - 2;
        targetElem.style.height = getH + "px";
      }
    });
  },

  setFilterHeight: function () {
    setTimeout(() => {
      var filterElem = document.querySelector(
        ".search-filter-block .filter-content-block"
      );
      var targetElem = document.querySelector(
        ".search-filter-block .events-list"
      );
      if (targetElem && filterElem) {
        var windowH = window.innerHeight;

        var elementTop = targetElem.getBoundingClientRect().top;
        var wrapperPaddingB = parseInt(
          getComputedStyle(document.querySelector(".main-layout-wrapper"))
            .paddingBottom
        );
        var getH = windowH - elementTop - wrapperPaddingB - 2;
        filterElem.style.height = getH + 2 + "px";
        filterElem.style.top = elementTop - wrapperPaddingB - 2 + "px";
      }
    });
  },

  toggleDetails: function () {
    var contentBlock = document.querySelector(".page-content-block");
    contentBlock.classList.toggle("event-selected");
  },

  scrollToActive: function () {
    var linkArray = document.querySelectorAll(".sidebar-menu li a");
    var activeLink = document.querySelector(".sidebar-menu li a.active");
    var sidebarMenu = document.querySelector(".sidebar-menu");
    if(sidebarMenu){
      sidebarMenu.scrollLeft = activeLink ? activeLink.offsetLeft - 50 : 0;
    linkArray.forEach((linkArrayItem) => {
      linkArrayItem.addEventListener("click", () => {
        var clickedOffset = linkArrayItem.offsetLeft - 50;
        if (window.innerWidth < 768) {
          console.log(clickedOffset);
          sidebarMenu.scrollLeft = clickedOffset;
        }
      });
    });
    }
    
  },

  scrollToActiveTab: function () {
    setTimeout(() => {
      var linkArray = document.querySelectorAll(
        ".event-details-block .primary-tab-nav .nav-item a"
      );
      var sidebarMenu = document.querySelector(
        ".event-details-block .primary-tab-nav"
      );
      // console.log(linkArray, "here it goes..");
      if(sidebarMenu){
        linkArray.forEach((linkArrayItem) => {
          linkArrayItem.addEventListener("click", () => {
            var clickedOffset = linkArrayItem.offsetLeft - 50;
            sidebarMenu.scrollLeft = clickedOffset;
          });
        }, 200);
      }
      
    });
  },
};

export default designService;
