import {
  CREATEROOM_BEGIN,
  CREATEROOM_SUCCESS,
  CREATEROOM_FAILURE,
  INFO_BEGIN,
  INFO_SUCCESS,
  INFO_FAILURE,
  UPDATE_ROOM_LIST,
} from "./explore.constant";
import { http } from "./../../../utils/index";
import { showMessage } from "../../../store/common.action";
import {
  changeCurrentCard,
  updateMidbarData,
} from "layout/Midbar/store/midbar.action";

export const infoBegin = () => {
  return {
    type: INFO_BEGIN,
  };
};

export const infoSuccess = (payload) => {
  return {
    type: INFO_SUCCESS,
    payload,
  };
};

export const infoFailure = (error) => {
  return {
    type: INFO_FAILURE,
    payload: error,
  };
};

export const createRoomBegin = () => {
  return {
    type: CREATEROOM_BEGIN,
  };
};

export const createRoomSuccess = (payload) => {
  return {
    type: CREATEROOM_SUCCESS,
    payload,
  };
};

export const createRoomFailure = (error) => {
  return {
    type: CREATEROOM_FAILURE,
    payload: error,
  };
};

export const fetchInfo = (currentCard, successCB) => {
  const request = http(
    "get",
    `user/assets/getAssetsInfo?assetId=${currentCard}`,
    {},
    true
  );
  return (dispatch) => {
    dispatch(infoBegin());
    request
      .then((response) => {
        successCB();
        dispatch(infoSuccess(response.data));
      })
      .catch((error) => {
        console.log("error info", error);
        dispatch(infoFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const createRoom = (payload, successCB) => {
  const request = http("POST", "user/rooms/createRoom", payload, true);
  return (dispatch) => {
    dispatch(createRoomBegin());
    request
      .then((response) => {
        dispatch(updateMidbarData([]));
        dispatch(changeCurrentCard(null));

        localStorage.setItem(
          "redirectData",
          JSON.stringify({ flag: false, redirectObj: {} })
        );
        dispatch(showMessage(response?.message, "info"));
        dispatch(createRoomSuccess(response));
        successCB();
        // dispatch(changeRedirectFlag(false, {}, "myroom"));
      })
      .catch((error) => {
        console.log(error);
        dispatch(createRoomFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const updateRoom = (data) => {
  return {
    type: UPDATE_ROOM_LIST,
    payload: data,
  };
};

export const updateRoomData = (data) => {
  return (dispatch) => {
    dispatch(updateRoom(data));
  };
};

export const roomListFetch = (
  tab,
  currentCard,
  page,
  per_page,
  successCB,
  cancel
) => {
  const request = http(
    "GET",
    tab !== "invited"
      ? `user/assets/getAssets?type=${tab}&assetId=${currentCard}&page=${page}&per_page=${per_page}`
      : `user/assets/getInvitedRoom?assetId=${currentCard}&page=${page}&per_page=${per_page}`,
    {},
    true,
    {},
    cancel
  );
  return (dispatch) => {
    request
      .then((response) => {
        successCB && successCB(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
