import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function DashboardSkeleton() {
  return (
    <div className="content-skeleton">
      {[1, 2, 3, 4, 5, 6, 7].map((each) => (
        <div key={each} className="slider-skeleton mb-4">
          <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
            <Skeleton count={1} width={100} className="mb-1" />
          </SkeletonTheme>
        </div>
      ))}
    </div>
  );
}

export default DashboardSkeleton;
