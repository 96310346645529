import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import deleteRed from "assets/images/icons/delete-red.svg";
import noInvitedUser from "assets/images/icons/no-invited-user.svg";
import { invitedUsers } from "./store/room.action";
import { deleteInvitee } from "./store/room.action";
import DeleteInviteeModal from "./components/DeleteInviteeModal";
import { getDateInFormat } from "utils";

function InvitedUserTable() {
  const dispatch = useDispatch();
  const { currentCard } = useSelector(({ midbar }) => midbar);
  const { invitedUsers: invitedUsersList } = useSelector(({ room }) => room);
  const [modal, setModal] = useState(false);
  const [deletePlayerId, setDeletePlayerId] = useState(null);

  useEffect(() => {
    currentCard && dispatch(invitedUsers(currentCard, () => {}));
    // eslint-disable-next-line
  }, [dispatch]);

  const handleDelete = () => {
    const successCB = () => {
      setModal(!modal);
    };
    dispatch(deleteInvitee(deletePlayerId, successCB));
  };

  return invitedUsersList?.length === 0 ? (
    <div className="no-data-block no-invited-user">
      <div className="no-data-content-block">
        <p className="font-18 fw-medium text-center">No Invited User</p>
        <p className="font-14 fw-light text-center">
          You haven’t yet Invited or add user. To invite User please put the
          email of user that you want to add in this room
        </p>
      </div>
      <div className="img-wrapper">
        <img src={noInvitedUser} alt="" />
      </div>
    </div>
  ) : (
    <>
      <Table responsive className="custom-table">
        <thead>
          <tr>
            <th className="font-14 fw-normal">User</th>
            <th className="font-14 fw-normal text-center">Since</th>
            <th className="font-14 fw-normal text-center">Time spent</th>
            <th className="font-14 fw-normal text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {invitedUsersList?.map((eachInvitie) => {
            return (
              <tr key={eachInvitie._id}>
                <td className="font-14 fw-normal">
                  {/* {eachInvitie.name} */}
                  <span className="d-block font-11 fw-normal mt-1">
                    {eachInvitie.invitedUser}
                  </span>
                </td>
                <td className="font-14 fw-normal text-center">
                  {eachInvitie?.createdAt &&
                    (getDateInFormat(eachInvitie?.createdAt) || "-")}
                </td>
                <td className="font-14 fw-normal text-center">-</td>
                <td className="d-flex font-14 fw-normal action-column justify-content-center">
                  <button
                    type="button"
                    className="delete-btn mt-2"
                    onClick={() => {
                      setModal(!modal);
                      setDeletePlayerId(eachInvitie._id);
                    }}
                  >
                    <img src={deleteRed} alt="delete-red" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <DeleteInviteeModal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default InvitedUserTable;
