import { useState } from "react";
import { useDispatch } from "react-redux";
import { Label, Input } from "reactstrap";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import closeWhite from "assets/images/icons/close-white.svg";
import filterWhite from "assets/images/icons/filter-white.svg";
import designService from "services/design.service";
import { changeSocialFlag } from "pages/Social/store/social.action";
// import { changeRedirectFlag } from "pages/Explore/store/explore.action";

function AllListings({
  module,
  roomTypeFilter,
  setroomTypeFilter,
  setPage,
  setSort,
  setDuration,
  setUserType,
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    sort: "descDate",
    duration: 50,
    typeUser: 2,
  });
  const dispatch = useDispatch();

  const handleListingChange = (event) => {
    setPage(1);
    // dispatch(changeRedirectFlag(false, {}, "myroom"));
    localStorage.setItem(
      "redirectData",
      JSON.stringify({ flag: false, redirectObj: {} })
    );
    dispatch(changeSocialFlag(false));
    setroomTypeFilter(event.target.value);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    designService.setFilterHeight();
  };

  const handleChange = (event) => {
    setFilterOptions({
      ...filterOptions,
      [event.target.name]: event.target.value,
    });
  };

  const handleSliderChange = (value) => {
    setFilterOptions({ ...filterOptions, duration: value });
  };

  const handleUpdate = () => {
    setPage(1);
    setSort(filterOptions.sort);
      setDuration(filterOptions.duration);
    setUserType(Number(filterOptions.typeUser));
    toggleFilter();
    // dispatch(changeRedirectFlag(false, {}, "myroom"));
    localStorage.setItem(
      "redirectData",
      JSON.stringify({ flag: false, redirectObj: {} })
    );
  };
  const handleClear = () => {
    setFilterOptions({
      sort: "descDate",
      duration: 0,
      typeUser: 2,
    });
    setPage(1);
    setSort("descDate");
    setDuration(50);
    setUserType(2);
    toggleFilter();
    // dispatch(changeRedirectFlag(false,{}, "myroom"));
    localStorage.setItem(
      "redirectData",
      JSON.stringify({ flag: false, redirectObj: {} })
    );
  };
  return (
    <div className="filter-block-wrap d-flex justify-content-between align-items-center w-100">
      <div className="filter-dropdown">
        <Input
          type="select"
          name="select"
          className="primary-dropdown"
          onChange={handleListingChange}
          value={roomTypeFilter}
        >
          <option value="All Rooms">All Rooms</option>
          <option value="Host">Host</option>
          <option value="Invited">Invited</option>
          <option value="Public">Public</option>
        </Input>
      </div>
      <div className="filter-block">
        <button className="filter-button" title="Filter" onClick={toggleFilter}>
          <i className="filter-icon">
            <img src={filterWhite} alt="filter-white" />
          </i>
        </button>
        {isFilterOpen && (
          <div className="filter-content-block">
            <i className="close-icon" onClick={toggleFilter}>
              <img src={closeWhite} alt="close-whilte" />
            </i>
            <div className="header-wrap text-center">
              <h4 className="font-18 fw-normal mb-0">Filter</h4>
            </div>
            <div className="form-group-wrap text-right">
              <span
                className="font-13 fw-medium d-inline-block cursor-pointer text-blue"
                onClick={handleClear}
              >
                Clear all
              </span>
            </div>
            <div className="form-group-wrap">
              <div className="row">
                <Label className="font-14 fw-normal mb-2 col-12">Sort by date</Label>
                <div className="col-md-6 orange-radiobox d-flex align-items-center font-14 fw-normal">
                  <Input
                    type="radio"
                    name="sort"
                    className="radiobox-input"
                    value="ascName"
                    onChange={handleChange}
                    checked={filterOptions.sort === "ascName"}
                  />
                  <span className="radiobox-circle"></span>
                  Ascending by Name
                </div>
                <div className="col-md-6 orange-radiobox d-flex align-items-center font-14 fw-normal">
                  <Input
                    type="radio"
                    name="sort"
                    className="radiobox-input"
                    value="descName"
                    onChange={handleChange}
                    checked={filterOptions.sort === "descName"}
                  />
                  <span className="radiobox-circle"></span>
                  Descending by Name
                </div>
                <div className="col-md-6 orange-radiobox d-flex align-items-center font-14 fw-normal">
                  <Input
                    type="radio"
                    name="sort"
                    className="radiobox-input"
                    value="ascDate"
                    onChange={handleChange}
                    checked={filterOptions.sort === "ascDate"}
                  />
                  <span className="radiobox-circle"></span>
                  Ascending by Date
                </div>
                <div className="col-md-6 orange-radiobox d-flex align-items-center font-14 fw-normal">
                  <Input
                    type="radio"
                    name="sort"
                    className="radiobox-input"
                    value="descDate"
                    onChange={handleChange}
                    checked={filterOptions.sort === "descDate"}
                  />
                  <span className="radiobox-circle"></span>
                  Descending by Date
                </div>
              </div>
            </div>
            <div className="form-group-wrap">
              <Label className="font-14 fw-normal mb-1">Duration</Label>
              <div className="range-wrapper">
                <Slider
                  min={0}
                  max={50}
                  step={5}
                  value={filterOptions.duration}
                  labels={{ 0: "0", 25: "25min", 50: "50+" }}
                  name="duration"
                  onChange={handleSliderChange}
                  className="primary-range"
                />
              </div>
            </div>
            {module !== "social" ? (
              <div className="form-group-wrap">
                <div className="row">
                  <Label className="font-14 fw-normal mb-2 col-12">
                    User Type
                  </Label>
                  <div className="col-12 mb-2 orange-radiobox d-flex align-items-center font-14 fw-normal">
                    <Input
                      type="radio"
                      name="typeUser"
                      className="radiobox-input"
                      value={0}
                      onChange={handleChange}
                      checked={Number(filterOptions.typeUser) === 0}
                    />
                    <span className="radiobox-circle"></span>
                    Single User
                  </div>
                  <div className="col-12 mb-2 orange-radiobox d-flex align-items-center font-14 fw-normal">
                    <Input
                      type="radio"
                      name="typeUser"
                      className="radiobox-input"
                      value={1}
                      onChange={handleChange}
                      checked={Number(filterOptions.typeUser) === 1}
                    />
                    <span className="radiobox-circle"></span>
                    Multi User
                  </div>
                  <div className="col-12 mb-2 orange-radiobox d-flex align-items-center font-14 fw-normal">
                    <Input
                      type="radio"
                      name="typeUser"
                      className="radiobox-input"
                      value={2}
                      onChange={handleChange}
                      checked={Number(filterOptions.typeUser) === 2}
                    />
                    <span className="radiobox-circle"></span>
                    All User
                  </div>
                </div>
              </div>
            ) : null}

            <div className="footer-wrap d-flex justify-content-end">
              <button
                className="green-background-button text-center font-16 fw-normal d-flex justify-content-center align-items-center"
                onClick={() => handleUpdate()}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllListings;
