import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import Layout from "layout";
import defaultRoute from "./defaultRoute";


function AppRoute(route) {
  const { auth } = route;
  const isAuthenticated =   !Boolean(auth) || Boolean(localStorage.getItem("userToken"));
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>
        !isAuthenticated ? (
          <Redirect
          to={{
            pathname: defaultRoute,
            state: { from: props.location },
          }}
        />
        ) : (
          <Layout isChild={route.isChild} config={route?.layout?.config}>
            {route.routes ? (
              <>
                <route.component
                  {...props}
                  routes={route.routes}
                  auth={route.auth}
                />
                <Switch>
                  {route.routes.map((subroute) => (
                    <AppRoute
                      key={subroute.path}
                      auth={route.auth}
                      isChild={true}
                      {...subroute}
                    />
                  ))}
                </Switch>
              </>
            ) : (
              <route.component {...props} auth={route.auth} />
            )}
          </Layout>
          
         
        )
      }
    />
  );
}

export default AppRoute;
